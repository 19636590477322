<template>
  <v-dialog v-model="show" max-width="550" persistent>
    <v-card>
      <v-card-title class="titleStyle" :style="'max-height: 63px !important'">
        <v-col cols="11" class="ml-n4"> Histórico de atualização</v-col>
        <v-col cols="1" class="ml-4">
          <v-btn id="btnCloseX" icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text
        :style="'height: 420px !important; max-height: 300px !important;background-color: #e7e7fa'"
        id="cardScroll2"
        class="pa-2"
      >
        <v-card-text v-for="h in historics" v-bind:key="h.id" class="h-title pt-4 pb-0">
          <div class="row h-row mb-0 rounded">
            <div class="col-7 pl-0">
              <v-subheader style="max-height: 24px">Atualização realizada por </v-subheader>
              <v-subheader style="max-height: 24px"
                ><strong>{{ h.nome }}</strong></v-subheader
              >
            </div>
            <div class="col-5 pb-0">
              <v-subheader class="mt-0 h-line d-flex justify-end" style="max-height: 24px">Data/hora</v-subheader>
              <v-subheader class="d-flex justify-end" style="max-height: 24px"><strong>{{ h.dataAtualizacao }}</strong></v-subheader>
            </div> 
          </div>  
        </v-card-text>
      </v-card-text>
      <v-card-text class="h-title pb-5 h-footer" style="padding-top: 30px">
        <strong>Você visualizou todas as atualizações</strong> 
      </v-card-text>
      <v-card-actions>  
            <v-spacer />
        <v-btn color="success" @click="show = false" style="height: 48px" id="btnClose"> Fechar </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ModalIncidenceINSSHistoricUpdate',
  props: ['history'],
  created() {
    this.init();
  },
  data() {
    return {
      show: false,
      historics: [{
        nome: "Heleny Custódio",
        dataAtualizacao: "05/09/2023 - 09:20",
      }],
    };
  },
  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
      this.fetch();
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async fetch() { 
    },
  }
}
</script>
<style>
.bg-sub-title {
  background-color: #f9fafb;
}

.content-empty {
  background-color: #e7e7fa;
  color: #2a1c4f;
}
#cardScroll2 {
  flex-grow: 1;
  overflow: auto;
}

.content-empty {
  background-color: #fff;
}
.h-title {
  background-color: #e7e7fa;
}
.h-row {
  background-color: #fff;
}
.h-line {
  color: rgba(0, 0, 0, 0.6);
}
.h-footer {
  text-align: center;
  color: #2a1c4f !important;
}
hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#export-btn {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 2px solid var(--v-primary-base) !important;
  background-color: #fff !important;
  color: var(--v-primary-base);
}
#export-btn :hover {
  background-color: #e3ffee !important;
  width: 150%;
  height: 250%;
  border-radius: 3px;
}
#btnClose :hover {
  background-color: #e3ffee !important;
  border-radius: 3px;
  width: 170% !important;
  height: 200% !important;
  color: var(--v-primary-base);
}

.titleStyle {
  font-size: 20px;
  font-weight: 700 !important;

  line-height: 30px;
  align-content: center;
}
.v-dialog {
  border: 0px solid var(--v-secondary-lighten5) !important;
}
</style>